import React from "react";

export default function Rodape() {
  return (
    <div className="footer-end">
      <div className="end">
        Jati Conexões Ltda - Fone/WhatsApp: 19 99722-7281 - E-Mail:
        vendas1.jaticonexoes@gmail.com - Campinas/SP
      </div>
    </div>
  );
}
